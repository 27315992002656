import { VolunteerService } from './../volunteer.service';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Planning } from 'src/app/shared/models/planning/planning';
import * as moment from 'moment';
import { VolunteeringStatusEnum } from 'src/app/shared/enums/volunteer/volunteeringStatus.enum';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { Subscription } from 'rxjs';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { UserService } from 'src/app/shared/services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-volunteer-select',
  templateUrl: './volunteer-select.component.html',
  styleUrls: ['./volunteer-select.component.scss']
})
export class VolunteerSelectComponent implements OnInit {
  planning: Planning[] = [];
  isScrolledDown = false;
  selectedDays: Planning[] = [];
  sub: Subscription;
  scheduleSettings: ScheduleSettings;
  private destroyRef = inject(DestroyRef);

  constructor(
    public volunterService: VolunteerService, private loaderModalService: LoaderModalService, private userService: UserService) {}

  ngOnInit() {
    const startDate = moment();
    const endDate = moment()
      .add(4, 'months')
      .endOf('month');
    this.getVolunteerCalendar(startDate, endDate, 'DOWN');
    this.getScheduleSettings();
  }

  getVolunteerCalendar(startDate: moment.Moment, endDate: moment.Moment, scroll = 'DOWN') {
    this.loaderModalService.show();
    this.volunterService.getPlanning(startDate, endDate)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: planning => {
          if (scroll === 'DOWN') {
            this.planning = this.planning.concat(planning);
          }
          this.loaderModalService.dismiss();
        },
        error: () => {
          this.loaderModalService.dismiss();
        }
      }
      );
  }

  getScheduleSettings() {
    this.sub = this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(settings => {
      this.scheduleSettings = settings.scheduleSettings;
    });
  }

  onScrollDown() {
    const startDate = moment().add(5, 'months');
    const endDate = moment()
      .add(11, 'months')
      .endOf('month');
    this.getVolunteerCalendar(startDate, endDate, 'DOWN');
  }

  onScroll() {
    const planningContainerHeight = document.getElementById('volunteerCalendar');
    if (
      planningContainerHeight.scrollTop === planningContainerHeight.scrollHeight - planningContainerHeight.offsetHeight &&
      !this.isScrolledDown
    ) {
      this.isScrolledDown = true;
      this.onScrollDown();
    }
  }

  selectDay(selectedDays: Planning[]) {
    this.selectedDays = selectedDays;
  }

  goToSecondStep() {
    this.volunterService.volunteerData = this.selectedDays.map(selectedDay => {
      return {
        date: moment(selectedDay.date).format('YYYY-MM-DD'),
        status: selectedDay.events[0].meta.volunteeringStatus as VolunteeringStatusEnum
      };
    });
    this.volunterService.step = 2;
  }
}
