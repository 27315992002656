import { ShiftType } from './../../../shared/enums/home/shiftType.enum';
import { AfterViewInit, Component, DestroyRef, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { VolunteerService } from '../volunteer.service';
import { AbsenceService } from '../../absence/absence.service';
import { PlanningDetails } from 'src/app/shared/models/planning/planningDetails';
import { VolunteeringStatusEnum } from 'src/app/shared/enums/volunteer/volunteeringStatus.enum';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/element';
import { EMPTY, Observable, catchError, finalize, of, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-volunteer-request',
  templateUrl: './volunteer-request.component.html',
  styleUrls: ['./volunteer-request.component.scss']
})
export class VolunteerRequestComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  private index = 0;
  private absenceService: AbsenceService = inject(AbsenceService);
  volunteerDataService: VolunteerService = inject(VolunteerService);
  volunteerWorkTypeEnum = ShiftType;
  plannings: PlanningDetails[] = [];
  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: true,
    width: 350,
    spaceBetween: 16,
  };
  hasOnlyPendingDay = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private snackBarService: SnackbarService,
    private loaderModalService: LoaderModalService) { }

  ngOnInit() {
    this.loaderModalService.show();
    this.volunteerDataService.volunteerData.forEach(day =>
      this.getDateDetails(day.date));

    this.hasOnlyPendingDay =
      this.volunteerDataService.volunteerData.filter(day => day.status !== VolunteeringStatusEnum.WAITING).length > 0;
  }

  ngAfterViewInit() {
    this.swiper.nativeElement.swiper.activeIndex = this.index;
  }

  getDateDetails(date: string) {
    this.absenceService.getPlanningDetails(date)
      .pipe(
        catchError(() => of({
          period: PeriodType.EMPTY,
          shifts: [],
          numberOfPoints: -1,
          departure: null,
          arrival: null,
          label: null,
          type: null,
          reference: null,
        } as PlanningDetails)), // Casting the object to PlanningDetails
        tap(planningDetails => this.addPlanning(planningDetails, date)),
        finalize(() => this.loaderModalService.dismiss()),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();

  }

  goToPrevStep() {
    this.volunteerDataService.step = 1;
  }

  validate() {
    this.deleteOrValidateVolunteer(this.volunteerDataService.validate());
  }

  cancel() {
    this.deleteOrValidateVolunteer(this.volunteerDataService.delete());
  }

  slideChange(swiper: any) {
    this.index = swiper.detail[0].activeIndex;
  }
  private addPlanning(planningDetails: PlanningDetails, date: string) {
    planningDetails.date = date;
    this.plannings.push(planningDetails);
    this.plannings = this.plannings.sort((a, b) => (new Date(a.date) > new Date(b.date)) ? 1 : -1);
  }

  private deleteOrValidateVolunteer(action$: Observable<any>): void {
    action$.pipe(
      catchError(() => {
        this.snackBarService.show('VOLUNTEER.REQUEST.ERROR', 'error');
        return EMPTY;
      }),
      tap(() => {
        this.volunteerDataService.step = 1;
        this.snackBarService.show('VOLUNTEER.REQUEST.SUCCESS', 'success');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
