import { Component, DestroyRef, Input, OnChanges, OnDestroy, SimpleChanges, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import * as moment from 'moment';
import { PlanningService } from '../planning.service';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { HomeDayCalendar } from 'src/app/shared/models/home/homedayCalendar';
import { InstructionLink } from 'src/app/shared/models/planning/link';
import { DocumentsService } from '../../documents/documents.service';
import { Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-planning-weeks',
  templateUrl: './planning-weeks.component.html',
  styleUrls: ['./planning-weeks.component.scss']
})
export class PlanningWeeksComponent implements OnChanges, OnDestroy {
  @Input() datesOfWeek;
  periodType = PeriodType;
  planning: HomeDayCalendar[] = [];
  plannigsWeeks = [];

  private subscriptions = new Subscription();
  private destroyRef = inject(DestroyRef);

  constructor(
    private planningService: PlanningService,
    private router: Router,
    private loaderModalService: LoaderModalService,
    private documentService: DocumentsService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.generatePlannigWeeks(changes.datesOfWeek.currentValue);
  }

  generatePlannigWeeks(dates) {
    this.planning = [];
    this.plannigsWeeks = [];
    this.loaderModalService.show();
    this.subscriptions.add(this.planningService.getPlanningData(dates.first, dates.last)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: res => {
          this.planning = res.planning;
          this.setplanningData();
          this.setCharterInstructionsLinks(res.charterInstructions);
          this.plannigsWeeks.push(this.planning.slice(0, 7));
          this.plannigsWeeks.push(this.planning.slice(this.planning.length - 7, this.planning.length));
          this.setRosterInstructionsLinks(res.rosterInstructions);
          this.loaderModalService.dismiss();
        },
        error: () => {
          this.loaderModalService.dismiss();
        }
      }
      ));
  }

  setplanningData() {
    this.planning.forEach(day => {
      let duration = 0;
      day.parts.forEach(part => {
        if (part.details !== null) {
          const departure = moment(part?.details.departure?.date);
          const arrival = moment(part?.details.arrival?.date);
          duration = duration + moment.duration(arrival.diff(departure)).as('minutes');
        }
      });
      day.duration = duration;
      day.passed = moment(day.date).isBefore(moment(new Date().setHours(0, 0, 0, 0)));
    });
  }

  setCharterInstructionsLinks(charterInstructions: InstructionLink[]) {
    this.planning.forEach(day => {
      charterInstructions.forEach(charterInstruction => {
        if (moment(day.date).isSame(charterInstruction.date)) {
          day.isCloudLink = !charterInstruction.link && !!charterInstruction.cloudPath;
          day.link = day.isCloudLink ? charterInstruction.cloudPath : charterInstruction.link;
        }
      });
    });
  }

  setRosterInstructionsLinks(rosterInstructions: InstructionLink[]) {
    this.plannigsWeeks.forEach(plannigsWeek => {
      rosterInstructions.forEach(rosterInstruction => {
        if (
          moment(rosterInstruction.date).isBetween(plannigsWeek[0].date, plannigsWeek[6].date) ||
          moment(rosterInstruction.date).isSame(plannigsWeek[0].date) ||
          moment(rosterInstruction.date).isSame(plannigsWeek[6].date)
        ) {
          plannigsWeek.link = rosterInstruction.link;
        }
      });
    });
  }

  navigateToDetails(day) {
    if (day.date) {
      this.router.navigate(['/planning/details'], { queryParams: { from: moment(day.date).format('YYYY-MM-DD') } });
    }
  }

  downloadCharterInstructions(day: HomeDayCalendar) {
    if (!day.link) {
      return;
    }
    if (day.isCloudLink) {
      this.subscriptions.add(this.documentService.getFilePath(day.link)
      .pipe(takeUntilDestroyed(this.destroyRef))  
      .subscribe((url: string) => window.open(url, '_blank')));
    } else {
      window.open(day.link, '_blank');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
