import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Prepay } from 'src/app/shared/interfaces/prepay';
import { PrepayService } from 'src/app/features/prepay/prepay.service';
import { Router } from '@angular/router';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PrepaySettings } from 'src/app/shared/models/settings/prepaySettings/PrepaySettings';
import { CounterDisplayEnum } from 'src/app/shared/enums/setting/prepay-settings/counter-display.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-prepay',
  templateUrl: './prepay.component.html',
  styleUrls: ['./prepay.component.scss']
})
export class PrepayComponent implements OnInit {
  prepays: Prepay[] = [];
  mostRecent: Prepay;
  prepaySettings: PrepaySettings;
  public counterDisplayEnum = CounterDisplayEnum;
  private destroyRef = inject(DestroyRef);

  constructor(
    private prepayService: PrepayService,
    private userService: UserService,
    private loaderModalService: LoaderModalService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getPrepaySettings();
    this.loaderModalService.show();
    this.prepayService.getPrepays()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: res => {
          this.mostRecent = res[0];
          this.prepays = res.slice(1);
          this.loaderModalService.dismiss();
        },
        error: () => {
          this.loaderModalService.dismiss();
        }
      });
  }

  getPrepaySettings(){
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typoSetting => {
      this.prepaySettings = typoSetting.prepaySettings;
    });
  }

  navigateToPrepayDetail(prepay: Prepay) {
    this.router.navigate(['/prepay/details', prepay.start.substring(0, 10), prepay.end.substring(0, 10)]);
  }
}
