import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { PrepayService } from '../prepay.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { PrepayDescription } from 'src/app/shared/models/prepay/prepay-description-counters';
import { Router } from '@angular/router';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PrepaySettings } from 'src/app/shared/models/settings/prepaySettings/PrepaySettings';
import { CounterDisplayEnum } from 'src/app/shared/enums/setting/prepay-settings/counter-display.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-prepay-description',
  templateUrl: './prepay-description.component.html',
  styleUrls: ['./prepay-description.component.scss']
})
export class PrepayDescriptionComponent implements OnInit {
  descriptionCounters: PrepayDescription[];
  prepaySettings: PrepaySettings;
  public counterDisplayEnum = CounterDisplayEnum;
  private destroyRef = inject(DestroyRef);

  constructor(
    private prepayService: PrepayService,
    private snackBarService: SnackbarService,
    private loaderModalService: LoaderModalService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getPrepaySettings();
    this.loaderModalService.show();
    this.prepayService.getCountersDescription()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({next: res => {
      this.descriptionCounters = res;
      this.loaderModalService.dismiss();
    },
      error: () => {
        this.loaderModalService.dismiss();
        this.snackBarService.show('PREPAY.COUNTERS.ERROR', 'error');
      }
      } 
    );
  }

  getPrepaySettings() {
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typoSetting => {
      this.prepaySettings = typoSetting.prepaySettings;
    });
  }

  getBack() {
    this.router.navigate(['/prepay']);
  }
}
