import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import * as moment from 'moment';
import { Planning } from 'src/app/shared/models/planning/planning';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PlanningService } from '../planning.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-plannig-year',
  templateUrl: './plannig-year.component.html',
  styleUrls: ['./plannig-year.component.scss']
})
export class PlannigYearComponent implements OnInit {
  planning: Planning[] = [];
  previousMonths = 3;
  @Input() scheduleSettings: ScheduleSettings;
  private destroyRef = inject(DestroyRef);
  constructor(
    private planningService: PlanningService,
    private loaderModalService: LoaderModalService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
      this.getPlanning();
  }

  getPlanning() {
    const startDate = moment()
      .subtract(this.previousMonths, 'months')
      .startOf('month');
    const endDate = moment()
      .add(11, 'months')
      .endOf('month');
    this.loaderModalService.show();
    this.planningService.getPlanning(startDate, endDate)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: planning => {
          this.planning = planning;
          this.loaderModalService.dismiss();
        },
        error: () => {
          this.loaderModalService.dismiss();
        }
      }  
    );
  }
}
