import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { SelectableItem } from '@shared/interfaces/selectableItem';
import { Database } from './reset-password.model';
import { API_URLS } from 'src/environments/api-urls';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  private readonly httpClient = inject(HttpClient);

  getDataBases(): Observable<SelectableItem[]> {
    return this.httpClient.get<Database[]>(API_URLS.GET_SITES).pipe(
      map(databases =>
        databases.map(db => ({
          code: db.id?.toString(),
          label: db.name
        }))
      )
    );
  }
}
