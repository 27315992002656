<div *ngIf="!isloader" class="ubuntu-bold font-size-24 mb-2">Mon service</div>
<div *ngIf="!isloader" class="swap-service-detail">
    <div class="vacations">
        <div>
            <div class="ubuntu-bold font-size-12 duration">
                {{'PLANNING.PLANNING_WEEKS.TILE.INFOS.DURATION' | translate}} {{duration | hoursMinutesConverter}}</div>
        </div>
        <ng-container>
            <div>
                <div class="timeline">
                    <ng-container *ngFor="let part of planningDetails?.shifts">
                        <div
                            class="d-flex flex-column justify-content-between timeline-item bg-white rounded ms-3 p-4 shadow">
                            <div class="d-flex flex-row justify-content-between pb-2">
                                <span *ngIf="part?.reference !== '-1'"
                                    class="ubuntu-bold font-size-12">{{part?.reference}}</span>
                                <div class="d-flex flex-row ms-auto vehicle">
                                    <div *ngIf="part?.details?.vehicleCode"
                                        class="icon icon-12 icon-vehicle-grey me-2 mt-1">
                                    </div>
                                    <span class="ubuntu-bold font-size-12">{{part?.details?.vehicleCode}}</span>
                                </div>
                            </div>
                            <div>
                                <span class="ubuntu-bold font-size-12">{{part?.details?.departure?.date |
                                    hourFormat:':':'HH:mm'}}
                                    {{part?.details?.departure?.locationCode }}</span><br>
                                <span class="ubuntu-bold font-size-12">{{part?.details?.arrival?.date |
                                    hourFormat:':':'HH:mm'}}
                                    {{part?.details?.arrival?.locationCode}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="isloader" style="margin-right: 35rem; margin-top: 72px;">
    <app-loader></app-loader>
</div>