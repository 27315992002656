import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Counter } from 'src/app/shared/models/counters/counters';
import { Format } from 'src/app/shared/enums/counters/balanceFormat.enum';
import { AbsenceService } from '../absence.service';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-counter-balance-modal',
  templateUrl: './counter-balance-modal.component.html',
  styleUrls: ['./counter-balance-modal.component.scss']
})
export class CounterBalanceModalComponent implements OnInit {

  @Input() date: moment.Moment;
  @Input() absenceType: 'PARTIAL' | 'FULL_DAY';
  counters: Counter[] = [];
  format = Format;
  private destroyRef = inject(DestroyRef);

  constructor(
    private absenceService: AbsenceService,
    public activeModal: NgbActiveModal,
    public loaderModalService: LoaderModalService
  ) {}

  ngOnInit() {
    this.getCountersBalance();
  }

  getCountersBalance() {
    this.loaderModalService.show();
    this.absenceService.getCountersBalance(this.date.format('YYYY-MM-DD'), this.absenceType, null)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (counters: Counter[]) => {
          this.counters = counters.map(counter => ({ ...counter }));
          this.loaderModalService.dismiss();
        },
        error: () => {
          this.loaderModalService.dismiss();
        }
      });
  }

}
