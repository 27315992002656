import { SwapStepsService } from './../swap-steps.service';
import { Planning } from './../../../shared/models/planning/planning';
import { SwapService } from './../swap.service';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import * as moment from 'moment';
import { swapPlanningEnum } from '../enums/planning.enum';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Subscription } from 'rxjs';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-swap-request-calendar',
  templateUrl: './swap-request-calendar.component.html',
  styleUrls: ['./swap-request-calendar.component.scss']
})
export class SwapRequestCalendarComponent implements OnInit {

  i18nPrefix = 'SWAP_REQUEST.CALENDAR.';
  planning: Planning[];
  daySelected: Planning;
  sub: Subscription;
  scheduleSettings: ScheduleSettings;
  private destroyRef = inject(DestroyRef);

  constructor(
    private swapService: SwapService,
    private stepsService: SwapStepsService,
    private loaderModalService: LoaderModalService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.stepsService.continueEnable(false);
    this.getPlanning();
    this.sub = this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(
      settings => {
        this.scheduleSettings = settings.scheduleSettings;
      }
    );
  }

  selectDay(day: Planning) {
    if (this.daySelected === day) {
      this.daySelected = null;
      this.stepsService.selectDate(null);
      this.stepsService.setShiftId(null);
      this.stepsService.continueEnable(false);
    } else {
      this.daySelected = day;
      this.stepsService.selectDate(moment(day.date));
      this.stepsService.setShiftId(day.events[0].meta.shiftId);
      this.stepsService.continueEnable(true);
      this.stepsService.setUserShift(day.events[0].meta);
    }
    this.stepsService.resetSwapSearch();
  }

  getPlanning() {
    const start = moment();
    const end = moment().add(2, 'month').endOf('month');
    this.loaderModalService.show();
    this.swapService.getPlanning(swapPlanningEnum.SERVICE, start, end)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: planning => {
          this.loaderModalService.dismiss();
          this.planning = planning;
          this.getSelectedDay();
        },
        error: () => {
          this.loaderModalService.dismiss();
        }
      });
  }

  getSelectedDay() {
    const selectedDay = this.stepsService.selectedDate;
    if (selectedDay) {
      const IndexMonth = this.planning.findIndex(planning => {
        return planning.month.getMonth() === selectedDay.month();
      });
      const Indexday = this.planning[IndexMonth].events.findIndex(day => {
        return day.start.getDate() === selectedDay.date();
      });
      this.daySelected = {
        date : this.planning[IndexMonth].events[Indexday].start,
        month: this.planning[IndexMonth].month,
        events: []
      };
      this.stepsService.continueEnable(true);
    }
  }
}
